// extracted by mini-css-extract-plugin
export var button = "Presentations-module--button--yKAyC";
export var button1 = "Presentations-module--button1--7J0jB";
export var button2 = "Presentations-module--button2--VLhZb";
export var card = "Presentations-module--card--2ytp3";
export var container = "Presentations-module--container--TF9WT";
export var container2 = "Presentations-module--container2--M4OME";
export var overflow = "Presentations-module--overflow--k21fZ";
export var scrollSnap = "Presentations-module--scrollSnap--4BSIn";
export var section = "Presentations-module--section--xFEZv";
export var time = "Presentations-module--time--QyhFt";
export var timeline = "Presentations-module--timeline--NxaDV";
export var timelineBG = "Presentations-module--timelineBG--4TXOe";
export var video = "Presentations-module--video--x52hd";